exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gas-2021-js": () => import("./../../../src/pages/gas-2021.js" /* webpackChunkName: "component---src-pages-gas-2021-js" */),
  "component---src-pages-gas-js": () => import("./../../../src/pages/gas.js" /* webpackChunkName: "component---src-pages-gas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jul-nyar-js": () => import("./../../../src/pages/jul-nyar.js" /* webpackChunkName: "component---src-pages-jul-nyar-js" */),
  "component---src-pages-lunch-js": () => import("./../../../src/pages/lunch.js" /* webpackChunkName: "component---src-pages-lunch-js" */),
  "component---src-pages-restaurang-js": () => import("./../../../src/pages/restaurang.js" /* webpackChunkName: "component---src-pages-restaurang-js" */)
}

